<template>
  <div>
    <div class="d-flex w-100 title-abs title-img">
      <span class="title-span">停车方式比例</span>
    </div>
    <div class="imgBox">
      <Echart
        ref="chart"
        :options="options"
        id="parkingLotProportionChart"
        height="220px"
        width="280px"
      ></Echart>
      <!-- <div class="infoBox">
        <div class="box">
          <div style="width: 100%; text-align: center">固定车</div>
          <div>{{ info.totalFixedProportion || 0 }}</div>
        </div>
        <div class="box">
          <div style="width: 100%; text-align: center">临时车</div>
          <div>{{ info.totalTemporaryProportion || 0 }}</div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  components: {
    Echart,
  },
  data() {
    return {
      info: {},
      options: {
        grid: {
          // height: "60%",
          top: 10,
          left: 35,
          right: 35,
        },
        series: [
          {
            name: "访问来源",
            type: "pie",
            radius: [50, 60],
            roseType: "radius", // 设置为玫瑰图
            data: [
              { value: 10, name: "固定停车" },
              { value: 1, name: "临时停车" },
            ],
            label: {
              show: true,
              position: "outside",
              formatter: function (params) {
                return `{name|${params.name}}\n{value|${params.value}}`;
              },
              rich: {
                name: {
                  fontSize: 14,
                  fontWeight: "bold",
                  lineHeight: 22,
                },
                value: {
                  fontSize: 12,
                  lineHeight: 18,
                },
              },
            },

            color: ["#F4A460", "#90EE90"], // 自定义颜色
          },
        ],

        legend: {
          orient: "horizontal", // 图例布局方式，水平排列
          bottom: 0, // 图例距离底部的距离
          data: ["固定停车", "临时停车"], // 图例的名称
        },
      },
      url: "/park/base/datascreen/homedata",
      timer: null,
      parkLotId: "",
    };
  },
  mounted() {
    // this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    init(id) {
      this.parkLotId = id;
      this.getData();
    },
    getData() {
      this.getAction(this.url, { parkLotId: this.parkLotId }).then((res) => {
        // console.log("停车方式比例", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          // this.info = res.result;
          this.options.series[0].data[0].value =
            res.result.totalFixedProportion || 0; //固定停车
          this.options.series[0].data[1].value =
            res.result.totalTemporaryProportion || 0; //临时停车
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>

<style scoped lang="scss" class>
.title-img {
  height: 79px;
  background-image: url("../../assets/imgs/titleImg7.png");
  background-size: 204% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding: 0 18px 0 78px;
  margin-bottom: 6px;
  z-index: 1;
  line-height: 29px;
}
.title-span {
  margin-top: 6px;
}
.imgBox {
  box-sizing: border-box;
  width: 302px;
  height: 219px;
  background: rgba(25, 96, 195, 0.1);
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  margin-top: -37px;
  // background-image: url("../../assets/imgs/boxImg8.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  z-index: 2;
  padding: 0 10px;
}
.infoBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .box {
    width: 45%;
    height: 210px;
    color: #17eafe;
    font-size: 18px;
    background-image: url("../../assets/icon/proportion.png");
    background-size: 150% 120%;
    background-position: 50% 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    // background: aqua;
  }
}
</style>
