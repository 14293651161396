<template>
  <div>
    <div
      class="d-flex w-100 title-abs MaxTitle-img"
      style="justify-content: space-between"
    >
      <span class="title-span">企业动态</span>
    </div>
    <div class="d-flex MaxBox10-box">
      <a-carousel autoplay style="width: 450px; height: 270px; margin: 0">
        <div
          class="imgBox"
          v-for="(item, index) in imgUrls"
          :key="'img' + index"
        >
          <img :src="item" alt="" />
        </div>
      </a-carousel>
      <div class="rightBox">
        <div class="name">鑫创达近期大事件</div>
        <!-- <div class="division"></div> -->
        <dv-decoration-2
          style="width: 100%; height: 10px; margin-bottom: 15px"
          :dur="3"
        />
        <div class="contentBox">
          <div class="scrollBox" ref="scrollBox">
            <a-timeline>
              <a-timeline-item
                v-for="item in form.parkInfoConnections"
                :key="item.id"
              >
                <div class="enterpriseTitle">
                  {{ item.title }}
                </div>
                <div class="enterpriseContent">
                  {{ item.content }}
                </div>
              </a-timeline-item>
            </a-timeline>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {},
      imgUrls: [],
      url: "/park/base/datascreen/parkcompanydynamic",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.getAction(this.url).then((res) => {
        // console.log("车场动态", res);
        if (res.success) {
          this.form = res.result;
          this.imgUrls = res.result.imageUrl
            ? res.result.imageUrl.split(",")
            : [];
          setTimeout(() => {
            this.startScrolling();
          }, 1000);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    startScrolling() {
      const scrollBox = this.$refs.scrollBox;
      const textHeight = scrollBox.scrollHeight;
      const duration = textHeight < 200 ? 0 : (textHeight / 20).toFixed(2); // 滚动速度为 30 像素/秒
      // console.log(duration, textHeight);
      scrollBox.style.animationDuration = `${duration}s`;
      scrollBox.style.animationDelay = "3s";
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/boxStyle.scss";
.imgBox {
  width: 530px;
  height: 298px;
  margin: 0;
}
.imgBox img {
  width: 100%;
  height: 298px;
  margin: 0;
}

::v-deep .ant-carousel .slick-dots {
  text-align: right;
  padding-right: 10px;
}

::v-deep .ant-carousel .slick-dots li {
  background-color: #999;
}

::v-deep .ant-carousel .slick-dots li.slick-active button {
  background-color: rgb(23, 234, 254);
}
.rightBox {
  height: 250px;
  width: 720px;
  margin-left: 20px;
  .name {
    font-size: 22px;
    font-weight: bold;
    color: #66ffff;
  }
}
.contentBox {
  height: 280px;
  overflow-y: auto;
}
.contentBox::-webkit-scrollbar {
  display: none;
}
.scrollBox {
  animation: scroll linear infinite;
  .enterpriseTitle {
    font-size: 18px;
    font-weight: bold;
    color: #66ffff;
  }
  .enterpriseContent {
    font-size: 18px;
    font-weight: 400;
    color: #deeaff;
    line-height: 26px;
    white-space: pre-line;
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
</style>
