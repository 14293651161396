<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">在场车辆</span>
    </div>

    <div class="box10-box" style="padding: 0">
      <!-- <Echart
        ref="chart"
        :options="options"
        id="quantityChart"
        height="260px"
        width="680px"
      ></Echart> -->
      <div class="infoBox">
        <div class="box">
          <div style="font-size: 28px; margin-bottom: 10px">
            {{ info.yesterdayFloorVehicle || 0 }}
          </div>
          <div style="width: 100%; text-align: center">昨日在场车辆</div>
        </div>
        <div class="box boxTow">
          <div style="font-size: 28px; margin-bottom: 10px">
            {{ info.todayFloorVehicle || 0 }}
          </div>
          <div style="width: 100%; text-align: center">今日在场车辆</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Echart from "@/common/echart";
export default {
  components: {
    // Echart,
  },
  data() {
    return {
      options: {
        xAxis: {
          type: "category",
          data: ["昨日在场车辆", "今日在场车辆"],
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 x 轴字体颜色
          },
        },
        yAxis: {
          type: "value",
          name: "车辆",
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 y 轴字体颜色
          },
        },
        grid: {
          height: "70%",
          top: 35,
          left: 35,
        },
        series: [
          {
            type: "bar", // 修改为柱形图
            data: [0, 0], // 数据格式改为柱形图所需的格式
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
            itemStyle: {
              color: "#17eafe", // 设置柱形图颜色
              barWidth: 2, // 设置柱形图宽度
              barMaxWidth: 5, // 设置柱形图最大宽度
            },
            barCategoryGap: "90%",
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}",
          axisPointer: {
            type: "shadow", // 显示柱形图阴影效果
            shadowStyle: {
              color: "rgba(0,0,0,0.3)", // 设置阴影颜色
            },
          },
        },
      },
      info: {},
      url: "/park/base/datascreen/homedata",
      timer: null,
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url).then((res) => {
        // console.log("在场车辆", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          this.info = res.result;
          // this.options.series[0].data[0] =
          //   res.result.yesterdayFloorVehicle || 0;//昨日
          // this.options.series[0].data[1] = res.result.todayFloorVehicle || 0;
          // this.$refs.chart.refresh(this.options);//今日
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>

<style scoped lang="scss" class>
@import "../assets/scss/boxStyle.scss";
.infoBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  .box {
    width: 45%;
    height: 240px;
    color: #17eafe;
    font-size: 18px;
    background-image: url("../assets/icon/yesterdayFloorVehicle.png");
    background-size: 95% 140%;
    background-position: 50% 50%;
    display: flex;
    align-content: flex-start;
    justify-content: center;
    align-content: flex-start;
    padding-top: 60px;
    flex-wrap: wrap;
    // background: aqua;
  }
  .boxTow {
    background-image: url("../assets/icon/todayFloorVehicle.png");
    background-size: 95% 96%;
  }
}
</style>
