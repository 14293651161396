<template>
  <div class="bottomBox">
    <div class="titleBox d-flex">
      <img
        src="@/assets/imgs/data.png"
        style="width: 21px; height: 20px; margin-right: 7px"
      /><span class="titleBox-title">{{ title }}</span>
    </div>
    <div class="bottom">
      <div class="total">
        {{ total || 0 }}<span class="unit">{{ unit }}</span>
      </div>
      <div class="amount">
        {{ labelOne }}：<span>{{ dataOne || 0 }}</span>
      </div>
      <div class="amount">
        {{ labelTwo }}：<span>{{ dataTwo || 0 }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
    dur: {
      type: Number,
      required: false,
      default: 3,
    },
    total: {
      type: [String, Number],
      required: true,
    },
    labelOne: {
      type: String,
      required: false,
      default: "",
    },
    dataOne: {
      type: [String, Number],
      required: false,
      default: "",
    },
    labelTwo: {
      type: String,
      required: false,
      default: "",
    },
    dataTwo: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.bottomBox {
  width: 303px;
  height: 155px;
  box-sizing: border-box;
  padding: 15px 0 15px 15px;
  background: rgba(25, 96, 195, 0.1);
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  // background-image: url("../assets/imgs/boxImg8.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  .titleBox-title {
    font-size: 20px;
    height: 22px;
    line-height: 22px;
    font-weight: 400;
    color: #26b0ff;
    margin-bottom: 15px;
  }
  .bottom {
    .total {
      height: 28px;
      font-size: 28px;
      font-weight: bold;
      color: #66ffff;
      margin-bottom: 14px;
      .unit {
        margin-left: 8px;
        font-size: 16px;
        font-weight: 400;
        color: #26b0ff;
      }
    }
    .amount {
      height: 16px;
      font-size: 16px;
      font-weight: 400;
      color: #26b0ff;
      margin-bottom: 4px;
    }
  }
}
</style>
