<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">车场实时监控</span>
    </div>

    <div class="box10-box" style="padding: 10px 0 0 10px">
      <div class="items" v-if="show">
        <div
          class="item"
          v-for="(item, index) in list.videoUrls"
          :key="'video' + index"
        >
          <flv :videoPath="item.videoUrl" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flv from "@/common/flv";
export default {
  components: {
    flv,
  },
  data() {
    return {
      url: "/park/base/datascreen/parkdevicevideo",
      list: { videoUrls: [] },
      show: false,
    };
  },
  mounted() {},

  methods: {
    init(id) {
      // console.log(id);
      this.list = { videoUrls: [] };
      this.show = false;
      this.getAction(this.url).then((res) => {
        // console.log("各车场实时监控", res.result);
        if (res.success) {
          let list = res.result.find((item) => item.parkLotId == id);
          // console.log("监控", list);
          this.list = list || { videoUrls: [] };
          this.show = true;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" class>
.title-img {
  width: 1754px;
  height: 79px;
  background-image: url("../../assets/imgs/titleImg10.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding-left: 78px;
  padding-right: 18px;
  margin-bottom: 6px;
  line-height: 29px;
  z-index: 1;
}
.title-span {
  margin-top: 6px;
}
.box10-box {
  box-sizing: border-box;
  width: 1754px;
  height: 315px;
  background: rgba(25, 96, 195, 0.1);
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  padding: 10px 25px 12px 24px;
  margin-top: -37px;
  // background-image: url("../../assets/imgs/boxImg11.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  z-index: 2;
}
.items {
  width: 1730px;
  height: 300px;
  // background: aqua;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.item {
  width: 415px;
  margin-right: 10px;
  height: 289px;
  margin-bottom: 10px;
  // background: saddlebrown;
}
</style>
