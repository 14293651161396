<template>
  <div>
    <div class="d-flex w-100 title-abs title-img">
      <span class="title-span">车场实时监控</span>
    </div>

    <!-- <div class="imgBox"> -->
    <div class="ParkLotbox" v-if="show">
      <div class="item" v-for="(item, index) in list" :key="'park' + index">
        <!-- <div class="itemTop">
            <span class="parkLotName">{{ item.parkLotName }}</span>
            <div class="more">
              更多
              <img
                src="@/assets/imgs/more.png"
                alt=""
                style="width: 16px; height: 16px"
              />
            </div>
          </div> -->
        <!-- <div class="itemBottom"> -->
        <!-- <div class="video" v-for="i in item.videoUrls" :key="i.id"> -->
        <flv :videoPath="item.videoUrl" />
        <!-- </div> -->
        <!-- </div> -->
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>
<script>
import flv from "@/common/flv";
export default {
  components: {
    flv,
  },
  data() {
    return {
      url: "/park/base/datascreen/parkdevicevideo",
      list: [{ videoUrls: [] }],
      show: false,
    };
  },
  mounted() {
    this.getdata();
  },
  methods: {
    getdata() {
      this.getAction(this.url).then((res) => {
        // console.log("各车场实时监控", res);
        if (res.success) {
          let list = [];
          // 最多显示两个监控视频
          // for (let index = 0; index < res.result.length; index++) {
          //   const element = res.result[index];
          //   if (element.videoUrls && element.videoUrls.length <= 2) {
          //     list.push(element);
          //   } else if (element.videoUrls && element.videoUrls.length > 2) {
          //     element.videoUrls = element.videoUrls.slice(0, 2);
          //     list.push(element);
          //   } else {
          //     element.videoUrls = [];
          //     list.push(element);
          //   }
          // }
          for (let index = 0; index < res.result.length; index++) {
            const element = res.result[index];
            if (element.videoUrls && element.videoUrls.length > 0) {
              for (let i = 0; i < element.videoUrls.length; i++) {
                list.push(element.videoUrls[i]);
              }
            }
          }
          if (list.length < 3) {
            this.list = list;
          } else {
            // this.list = list.slice(0, 2);
            // 随机取两个
            let n = list.length;
            let numbers = Array.from({ length: n }, (_, i) => i); //创建0-this.list.length的数组[0,1,2,3,...,this.list.length]
            // console.log('numbers1', numbers)
            let firstNumber = numbers.splice(
              Math.floor(Math.random() * numbers.length),
              1
            )[0]; //取第一个随机数并将该数字从numbers中删掉
            // console.log('firstNumber', firstNumber)
            // console.log('numbers2', numbers)
            let secondNumber = numbers.splice(
              Math.floor(Math.random() * numbers.length),
              1
            )[0]; //取第二个随机数并将该数字从numbers中删掉
            // console.log('secondNumber', secondNumber)
            this.list = [list[firstNumber], list[secondNumber]];
          }
          this.show = true;
          // console.log(this.list);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.title-img {
  height: 79px;
  background-image: url("../assets/imgs/titleImg2.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding-left: 78px;
  margin-bottom: 4px;
  line-height: 29px;
}
.title-span {
  margin-top: 6px;
}
// .imgBox {
//   width: 925px;
//   height: 310px;
//   margin-top: -30px;
//   position: relative;
//   z-index: 2;
//   // background: rgba(25, 96, 195, 0.3);
//   // box-shadow: inset 0px 0px 40px 1px #2952AF;
//   box-sizing: border-box;
//   // background-image: url("../assets/imgs/boxImg2.png");
//   background-size: 100% 100%;
//   background-repeat: no-repeat; /* 不重复平铺背景图片 */
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
.ParkLotbox {
  width: 912px;
  margin-top: -30px;
  // padding-top: 12px;
  height: 310px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 440px;
    height: 300px;
    // padding: 10px;
    margin: 0 0 0 15px;
    box-sizing: border-box;
    // box-shadow: inset 0px 0px 40px 1px #2952af;
    // border: 2px solid #2952af;
    .itemTop {
      display: flex;
      justify-content: space-between;
      .parkLotName {
        font-size: 16px;
        font-weight: 500;
        color: #deeaff;
      }
      .more {
        font-size: 14px;
        font-weight: 500;
        color: #1f76ec;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
    }
    .itemBottom {
      display: flex;
      justify-content: space-between;
      .video {
        width: 202px;
        height: 114px;
        margin-top: 10px;
        // background: aqua;
      }
    }
  }
}
.ParkLotbox::-webkit-scrollbar {
  height: 100%; /* 设置滚动条高度 */
  width: 5px;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 5px; /* 滑块边角弧度 */
}
</style>
