<template>
  <div ref="videoPlayerBox" class="component">
    <!-- <video class="videoPlayer video-js" style="width: 100%; height: 100%" controls></video> -->
    <iframe
      v-if="show"
      width="100%"
      height="100%"
      :src="'https://open.ys7.com/ezopen/h5/iframe?url=' + url + '&autoplay=1'"
    ></iframe>
    <!-- &accessToken=at.1hrevne4a3cssj2v7hg3w7c17exnpkgx-39ktt3e9pu-11meqlx-pxm1dqocy -->
  </div>
</template>
<script>
// import videojs from "video.js";
// import "video.js/dist/video-js.css";
// import "videojs-contrib-hls";
// import "videojs-flvjs-es6";

export default {
  props: {
    videoPath: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      player: null,
      streamURL: "",
      url: "",
      show: false,
    };
  },

  mounted() {
    this.init();
  },
  watch: {
    // videoPath(newValue) {
    //   console.log("newValue", newValue);
    //   if (newValue) {
    //     this.url = newValue;
    //     this.show = true; // 如果字段有值，将this.show设置为true
    //   }
    // },
  },
  beforeDestroy() {
    this.disposePlayer();
  },
  methods: {
    // 初始化播放器
    init() {
      if (!this.videoPath) {
        return true;
      }
      this.url = this.videoPath;
      this.show = true;
    },
    // init() {
    //   if (!this.videoPath) {
    //     return true
    //   }
    //   console.log(this.videoPath)
    //   this.$nextTick(() => {
    //     let playerOption = {
    //       preload: 'auto', // 预加载
    //       autoplay: false, // 自动播放
    //       controls: true,
    //       muted: true,
    //       techOrder: ['html5', 'flvjs', 'flash'], // 这里的顺序一定要 'flvjs' 在 'flash' 前面，要不然 flv 格式的就无法播放了
    //       // 如果报 app.js:242798 Uncaught TypeError: this.el_.vjs_getProperty is not a function 错误，只保留 'flash' 就不报错了
    //       // 报错 The "flash" tech is undefined. Skipped browser support check for that tech. 可以查看 videojs-flash 里面 node_modules 查看需要的 video.js 的版本，然后修改video.js的版本就可以了
    //       flash: {
    //         hls: { withCredentials: false }
    //         // swf: SWF_URL // 引入静态文件swf
    //       },
    //       flvjs: {
    //         mediaDataSource: {
    //           cors: true,
    //           withCredentials: false
    //         }
    //       },
    //       sources: [
    //         {
    //           src: this.videoPath,
    //           type: 'video/x-flv'
    //         }
    //       ]
    //     }

    //     this.player = videojs(this.$el.querySelector('.videoPlayer'), playerOption, function onPlayerReady() {
    //       console.log('onPlayerReady', this)
    //     })

    //     this.player.on('error', () => {
    //       console.log('播放失败')
    //       // 在播放失败时执行的操作，例如显示错误消息或重新加载视频
    //     })
    //   })
    // },
    // 重新加载播放器
    loadPlayer() {
      this.disposePlayer();
      this.$refs.videoPlayerBox.innerHTML = `<video class="videoPlayer video-js" style="width: 100%;height: 100%;" controls></video>`;
      this.$nextTick(() => {
        this.init();
      });
    },
    // 销毁播放器
    disposePlayer() {
      if (this.player) {
        this.player.dispose();
      }
    },
  },
};
</script>
<style scoped lang="scss">
.component {
  width: 100%;
  height: 100%;
}
// ::v-deep .video-js .vjs-picture-in-picture-control {
//   display: none;
// }
</style>
