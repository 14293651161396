<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">体育文化板块数据</span>
    </div>
    <div class="bigBox10-box" style="padding: 0">
      <Echart
        ref="chart"
        :options="options"
        id="stallChart"
        height="420px"
        width="680px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
export default {
  components: {
    Echart,
  },
  data() {
    return {
      options: {
        title: {
          show: false,
        },
        xAxis: {
          type: "value",
          show: false,
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 y 轴字体颜色
          },
        },
        yAxis: {
          type: "category",
          data: ["A", "B", "C", "D", "E", "F", "G"],
          axisLine: {
            show: false,
          },
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 y 轴字体颜色
          },
          axisTick: {
            show: false,
          },
        },
        grid: {
          height: "70%",
          top: -5,
          left: 30,
        },
        series: [
          {
            type: "bar",
            barWidth: "50%", // 设置柱状图的宽度为50%
            data: [
              { value: 92, name: "A" },
              { value: 10, name: "B" },
              { value: 20, name: "C" },
              { value: 30, name: "D" },
              { value: 20, name: "E" },
              { value: 80, name: "F" },
              { value: 60, name: "G" },
            ],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  //只要修改前四个参数就ok
                  offset: 0,
                  color: "rgb(75,104,235)",
                }, //柱图渐变色
                {
                  offset: 1,
                  color: "rgb(2,242,255)",
                },
              ]),
              // normal: {
              barBorderRadius: [15, 15, 15, 15], // 设置圆角
              // },
            },
            label: {
              show: true,
              position: "right",
              formatter: "{c} %",
              color: "#fff", // 设置数据点标签的字体颜色
            },
            // 配置提示框
            tooltip: {
              trigger: "axis", // 设置触发类型为坐标轴轴线上
              formatter: "{b} : {c}", // 显示类别名称和对应数值
              axisPointer: {
                type: "line", // 将悬浮指示器类型设为直线
                lineStyle: {
                  // 设置直线样式
                  color: "rgb(255,255,255)", // 指示器线条颜色
                  width: 1, // 指示器线条宽度
                },
              },
            },
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss" class>
@import "../assets/scss/boxStyle.scss";
</style>
