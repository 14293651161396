<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">进出场流量情况</span>
    </div>
    <div class="box10-box" style="padding: 0">
      <Echart
        ref="chart"
        :options="options"
        id="flowChart"
        height="260px"
        width="650px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
  components: {
    Echart,
  },
  data() {
    return {
      options: {
        title: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: [], // 根据实际情况填入数据
          axisLabel: {
            color: "rgb(255,255,255)",
          },
        },
        yAxis: {
          type: "value",
          name: "车辆",
          min: 0,
          interval: 30,
          axisLabel: {
            color: "rgb(255,255,255)",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#133d83",
            },
          },
        },
        grid: {
          height: "70%",
          top: 30,
          left: 35,
          right: 35,
        },
        series: [
          {
            type: "line", // 将类型改为折线图
            data: [], // 根据实际情况填入数据
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "#17eafe",
                },
                {
                  offset: 1,
                  color: "#019ed8",
                },
              ]),
            },
            label: {
              show: true,
              position: "top",
              color: "#fff",
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}",
          axisPointer: {
            type: "line", // 将类型改为直线
          },
        },
      },
      url: "/park/base/datascreen/recordInOut",
      timer: null,
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url).then((res) => {
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const xAxis = res.result.map((car) => car.date + ":00");
          const yAxis = res.result.map((car) => car.num);
          this.options.series[0].data = yAxis;
          this.options.xAxis.data = xAxis;
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/boxStyle.scss";
</style>
