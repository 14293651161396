<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">实时进出场车辆情况</span>
    </div>
    <div class="box10-box" style="padding: 8px 14px 14px 14px">
      <dv-scroll-board
        style="width: 100%; height: 100%"
        :config="config"
        ref="scrollBoard"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      config: {
        header: ["车牌号", "时间", "类型", "停车时长", "收入(元)"],
        data: [],
        index: false,
        columnWidth: [105, 90, 73, 90, 90],
        align: ["left", "center", "center", "center", "center"],
        headerBGC: "#274B9D",
        rowNum: 21,
      },
      url: "/park/base/datascreen/recordInAndOut",
      ws: null,
      parkLotId: "",
      timer: "",
    };
  },
  mounted() {
    // console.log(this.dateYear);
    // this.getData();
  },
  beforeDestroy() {
    this.closeWebSocket();
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    closeWebSocket() {
      if (this.ws) {
        this.ws.close();
      }
    },
    linkWS() {
      let that = this;
      let url =
        process.env.VUE_APP_API_BASE_WSURL + "/websocket/inout_parkLotId";
      this.ws = new WebSocket(url);

      // 当连接建立成功，触发 open 事件
      this.ws.onopen = (evt) => {
        console.log("建立连接成功 ...", evt);
        // 连接建立成功以后，就可以使用这个连接对象通信了
        // send 方法发送数据
        // this.ws.send("Hello WebSockets!");
      };
      this.ws.onerror = function (error) {
        console.log("WebSocket连接发生错误:", error);
      };
      // 当接收到对方发送的消息的时候，触发 message 事件
      // 我们可以通过回调函数的 evt.data 获取对方发送的数据内容
      this.ws.onmessage = function (evt) {
        // console.log("接收到消息: " + evt.data);
        let list = JSON.parse(evt.data);
        const transformedData = list.map((item) => {
          let Type = item.inoutType == 1 ? "出场" : "进场"; //0进场 1出场”
          return [
            item.carnum,
            item.time,
            Type,
            item.elapsedTime,
            item.payAmount,
          ];
        });
        that.$refs["scrollBoard"].updateRows(transformedData);
        // 当不需要通信的时候，可以手动的关闭连接
        // this.ws.close();
      };

      // 当连接断开的时候触发 close 事件
      this.ws.onclose = (res) => {
        console.log("关闭连接", res);
      };
    },

    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData(this.parkLotId);
      }, 60 * 1000);
    },
    getData(parkLotId) {
      this.parkLotId = parkLotId;
      if (!this.timer) {
        this.startTimer();
      }
      this.getAction(this.url, { parkLotId: parkLotId }).then((res) => {
        // console.log("实时进出场车辆情况", res);
        if (res.success) {
          // this.linkWS();
          this.config.data = [];
          // for (let index = 0; index < res.result.length; index++) {
          //   const element = res.result[index];
          //   let arr = [];
          //   arr.push(element.recordOutNum || "0"); //车牌号
          //   arr.push(element.recordInNum || "0"); //时间
          //   arr.push(element.drivewayNum || "0"); //类型
          //   arr.push(element.totalspaces || "0"); //停车时长
          //   arr.push(element.totalAmount || "0"); //收入(元)
          //   const stringArray = arr.map((value) => String(value));
          //   this.config.data.push(stringArray);
          // }
          // console.log(this.config.data);
          const transformedData = res.result.map((item) => {
            let Type = item.inoutType == 1 ? "出场" : "进场"; //0进场 1出场”
            // let carnum = `<span style="width:70px;">${item.carnum}</span>`;
            return [
              item.carnum,
              item.time,
              Type,
              item.elapsedTimeStr || "0h",
              item.payAmount,
            ];
          });
          this.$refs["scrollBoard"].updateRows(transformedData);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .header {
  background: linear-gradient(180deg, #0e1e4d 0%, #5474d5 100%);
  box-shadow: inset 0px -2px 6px 1px rgba(255, 255, 255, 0.16);
  margin-bottom: 6px;
}
::v-deep .row-item {
  background: none !important;
}
.title-img {
  height: 79px;
  background-image: url("../../assets/imgs/titleImg11.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding-left: 78px;
  margin-bottom: 6px;
  line-height: 29px;
  z-index: 1;
}
.title-span {
  margin-top: 6px;
}
.box10-box {
  box-sizing: border-box;
  width: 476px;
  height: 752px;
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  margin-top: -40px;
  background: rgba(25, 96, 195, 0.1);
  // background-image: url("../../assets/imgs/boxImg12.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  z-index: 2;
}
</style>
