<template>
  <div id="index" ref="appRef">
    <div class="bg"></div>
    <div class="copyBg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <div v-else class="host-body">
        <div class="d-flex top-title jc-center">
          <div
            class="h-100 d-flex jc-between"
            style="width: 1700px; color: #abc5f6"
          >
            <div
              class="d-flex jc-evenly ai-center fs-xp"
              style="height: 100px; width: 400px"
            >
              <div>
                <img
                  src="../assets/imgs/city.png"
                  class="pr-2"
                  style="width: 26px; height: 22px"
                />
                <span style="margin: 0 40px 0 3px">{{
                  city || "无法获取"
                }}</span>
              </div>
              <div>
                <img
                  src="../assets/imgs/district.png"
                  class="pr-2"
                  style="width: 32px; height: 23px"
                />
                <span style="margin-left: 3px">{{
                  district || "无法获取"
                }}</span>
              </div>
            </div>

            <div
              class="d-flex ai-center fs-xp"
              style="width: 400px; height: 100px"
            >
              <span style="margin-right: 1rem">{{ dateYear }}</span>
              <span style="margin-right: 1rem">{{ dateWeek }}</span>
              <span>{{ dateDay }}</span>
            </div>
          </div>
          <!-- <div style="background-color: aqua; width: 100%"></div> -->
        </div>

        <!--主体-->
        <div class="content">
          <div class="d-flex ai-center jc-center">
            <!--new左边-->
            <div class="box" style="margin-top: -50px">
              <div class="d-flex jc-space" style="margin-bottom: 4px">
                <carParkIncome style="margin-right: 16px" />
                <access />
              </div>

              <div class="d-flex jc-space" style="margin-bottom: 4px">
                <flow style="margin-right: 16px" />
                <quantity />
              </div>

              <div class="d-flex jc-space" style="margin-bottom: 4px">
                <saturation style="margin-right: 16px" />
                <stall :ParkLotList="ParkLotList" />
              </div>

              <div class="d-flex jc-space">
                <monitoring style="margin-right: 16px" />
                <proportion />
              </div>
            </div>

            <!-- 中间 -->
            <div style="margin: -9px 14px 0">
              <dv-border-Box-1 style="border-radius: 40px; z-index: 2">
                <centreBox
                  ref="centreBox"
                  :ParkLotList="ParkLotList"
                  @onMap="onMap"
                  style="margin-bottom: 4px"
                />
              </dv-border-Box-1>
              <income :ParkLotList="ParkLotList" />
            </div>
            <!--new右边-->
            <div class="box" style="margin-top: -50px">
              <introduce ref="introduce" style="margin-bottom: 4px" />
              <enterprise style="margin-bottom: 4px" />
              <div class="d-flex" style="margin-bottom: 4px">
                <store style="margin-right: 16px" />
                <sports />
              </div>
              <carParkList :ParkLotList="ParkLotList" />
            </div>
          </div>
          <!-- 中间弹出层 -->
          <div
            style="
              margin: -13px 0 0 6px;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              z-index: 999;
            "
          >
            <popupCentre ref="popupCentre" @close="close" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carParkIncome from "./carParkIncome"; //各车场收入排行
import access from "./access"; //实时进出场车辆情况   token失效跳转pc端逻辑在改组件
import flow from "./flow"; //进出场流量情况
import quantity from "./quantity"; //新增用户数量曲线改为在场车辆
import saturation from "./saturation"; //各车场饱和度情况
import stall from "./stall"; //车位变化趋势
import monitoring from "./monitoring"; //车场实时监控
import proportion from "./proportion"; //停车方式比例
import centreBox from "./centreBox"; //中间地图盒子
import income from "./income"; //车场总收入曲线统计
import introduce from "./introduce"; //停车场介绍
import enterprise from "./enterprise"; //企业动态
import store from "./store"; //商城数据信息预留
import sports from "./sports"; //体育文化板块数据
import carParkList from "./carParkList"; //机械车场链接
import popupCentre from "./popupCentre/popupCentre.vue"; // 中间弹出层
import drawMixin from "../utils/drawMixin";
import { formatTime } from "../utils/index.js";
// import axios from "axios";
export default {
  mixins: [drawMixin],
  data() {
    return {
      ParkLotList: [], // 车场列表
      timing: null,
      loading: true,
      dateDay: null,
      dateYear: null,
      dateWeek: null,
      weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
      showGoodsType: [10, 20, 30, 40, 50, 60],
      city: "",
      district: "",
    };
  },
  components: {
    centreBox,
    carParkList,
    monitoring,
    flow,
    quantity,
    sports,
    proportion,
    stall,
    carParkIncome,
    introduce,
    access,
    store,
    income,
    saturation,
    enterprise,
    popupCentre,
  },
  mounted() {
    this.timeFn();
    this.cancelLoading();
    this.address();
    this.getParkLotList();
  },
  beforeDestroy() {
    clearInterval(this.timing);
  },
  methods: {
    // 地图点击车场位置
    onMap(id) {
      let item = this.ParkLotList.find((item) => item.id == id);
      // console.log(id, item, this.ParkLotList);
      // console.log(item);
      if (item) {
        this.$refs.popupCentre.init(item);
      } else {
        this.$refs.popupCentre.init({ id: id });
      }
      this.$refs.introduce.parkLotIdGet(id);
    },
    // 关闭地图
    close() {
      this.$refs.introduce.close();
    },
    // 车场列表
    getParkLotList() {
      this.getAction("/park/base/datascreen/getParkLotList").then((res) => {
        if (res.success) {
          // console.log("车场列表", res);
          this.ParkLotList = res.result;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 当前地址
    address() {
      var data = {
        key: "2SKBZ-V45R3-TRC3O-REZDC-2BTWK-OQFL3",
      };
      var url = "https://apis.map.qq.com/ws/location/v1/ip"; //这个就是地理位置信息的接口
      data.output = "jsonp";
      this.$jsonp(url, data)
        .then((res) => {
          // console.log("地理位置", res);
          // this.city = res.result.ad_info.city; //市级
          // this.district = res.result.ad_info.district; //区级
          this.city = res.result.ad_info.province; //省级
          this.district = res.result.ad_info.city; //市级
          return res;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    // 当前时间
    timeFn() {
      this.timing = setInterval(() => {
        this.dateDay = formatTime(new Date(), "HH: mm: ss");
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.dateWeek = this.weekday[new Date().getDay()];
      }, 1000);
    },
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/index.scss";
.content {
  position: relative;
  padding: 0 18px;
  .box {
    box-sizing: border-box;
  }
}
.w-49 {
  width: 49%;
  .dv-border-svg-container {
    border: 4px solid rgb(41, 82, 175);
  }
  .left-top {
    display: none;
    border: none;
  }
  .right-top {
    display: none;
    border: none;
  }
  .right-bottom {
    border: none;
  }
  .left-bottom {
    border: none;
  }
}
.mb-2 {
  margin-bottom: 2rem;
}
.jc-space {
  justify-content: space-between;
}
.top-title {
  background-image: url("../assets/imgs/top.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.7;
  height: 160px;
  width: 100%;

  .time-year {
    background-image: url("../assets/img/time_year.png");
    background-repeat: no-repeat;
    width: 10px;
    height: 10px;
  }
}
</style>
