<template>
  <div v-if="show" class="parkingLot">
    <div class="img" @click="close">
      <img src="@/assets/imgs/close.png" alt="" />
    </div>

    <div class="middle d-flex">
      <div class="middleLeft" style="margin-right: 16px">
        <parkingLotdetails
          ref="parkingLotdetails"
          style="margin-bottom: 16px"
        />
        <div class="d-flex" style="margin-bottom: 16px">
          <parkingLotProportion
            ref="parkingLotProportion"
            style="margin-right: 20px"
          />
          <saturability ref="saturability" style="margin-right: 20px" />
          <parkingLotstall ref="parkingLotstall" />
        </div>
        <parkingLotIncome ref="parkingLotIncome" />
      </div>

      <div class="middleRihight">
        <!-- 实时进出场车辆情况 -->
        <inAndOut ref="inAndOut" />
      </div>
    </div>

    <!-- 监控 -->
    <monitoringBox ref="monitoringBox" />
  </div>
</template>

<script>
import parkingLotdetails from "./parkingLotdetails"; //单个车场详情
import parkingLotProportion from "./parkingLotProportion"; //单个车场停车比例
import saturability from "./saturability"; //单个车场饱和度
import parkingLotstall from "./parkingLotstall"; //单个车场车位变化趋势
import parkingLotIncome from "./parkingLotIncome"; //单个车场总收入曲线
import monitoringBox from "./monitoringBox"; //监控
import inAndOut from "./inAndOut"; //实时进出场车辆情况
import { nextTick } from "vue";
export default {
  components: {
    parkingLotProportion,
    saturability,
    parkingLotstall,
    parkingLotdetails,
    parkingLotIncome,
    monitoringBox,
    inAndOut,
  },
  data() {
    return {
      show: false,
      item: {},
    };
  },
  mounted() {},
  methods: {
    // 初始化
    init(item) {
      // console.log("车场id", item);
      this.item = item;
      this.show = true;
      nextTick(() => {
        this.$refs.saturability.init(item.id); //车场饱和度
        this.$refs.parkingLotstall.init(item.id); //单个车场车位变化趋势
        this.$refs.parkingLotIncome.init(item.id); //单个车场总收入曲线
        this.$refs.inAndOut.getData(item.id); //实时进出场车辆情况
        this.$refs.parkingLotProportion.init(item.id); //停车方式比例
        this.$refs.parkingLotdetails.init(item.id, item.name); //车场详情
        this.$refs.monitoringBox.init(item.id); //车场实时监控
      });
    },
    close() {
      this.show = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.parkingLot {
  width: 1803px;
  height: 1215px;
  margin-left: 10px;
  position: relative;
  box-sizing: border-box;
  padding: 25px 21px 9px 24px;
  background-color: rgba(0, 0, 0, 0.85);
  background-image: url("../../assets/imgs/popupCentre.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  z-index: 5;
  .middle {
    margin-bottom: 16px;
  }
  .img {
    position: absolute;
    width: 64px;
    height: 54px;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 5;
  }
}
</style>
