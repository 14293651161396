<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">商城数据信息预留</span>
    </div>
    <div class="bigBox10-box" style="padding: 0">
      <Echart
        ref="chart"
        :options="options"
        id="storeChart"
        height="350px"
        width="700px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";

export default {
  components: {
    Echart,
  },
  data() {
    return {
      options: {
        title: {
          show: false,
        },
        xAxis: {
          type: "category",
          data: ["A", "B", "C", "D", "E", "G", "H"],
          axisLabel: {
            color: "rgb(255,255,255)",
          },
        },
        yAxis: {
          type: "value",
          min: 0,
          max: 100,
          interval: 10,
          axisLabel: {
            formatter: "{value} %",
            color: "rgb(255,255,255)",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#133d83",
            },
          },
        },
        grid: {
          height: "70%",
          top: 10,
          left: 45,
          // right: 20,
        },
        series: [
          {
            type: "bar",
            data: [20, 30, 40, 10, 50, 40, 60],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  //只要修改前四个参数就ok
                  offset: 0,
                  color: "rgb(55,221,230)",
                }, //柱图渐变色
                {
                  offset: 1,
                  color: "rgb(136,105,247)",
                },
              ]),
            },
            label: {
              // 设置标签显示
              show: true, // 显示标签
              position: "top", // 标签显示在数据点的上方
              formatter: "{c} %",
              color: "#fff", // 设置数据点标签的字体颜色
            },
            barCategoryGap: "80%",
            barWidth: "35%",
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}%",
          axisPointer: {
            type: "shadow",
          },
        },
      },
    };
  },
  mounted() {},

  methods: {},
};
</script>

<style scoped lang="scss" class>
@import "../assets/scss/boxStyle.scss";
</style>
