import { render, staticRenderFns } from "./stall.vue?vue&type=template&id=790e9f79&scoped=true&"
import script from "./stall.vue?vue&type=script&lang=js&"
export * from "./stall.vue?vue&type=script&lang=js&"
import style0 from "./stall.vue?vue&type=style&index=0&id=790e9f79&prod&scoped=true&lang=scss&class=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "790e9f79",
  null
  
)

export default component.exports