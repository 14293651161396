<template>
  <div>
    <div class="d-flex w-100 title-abs title-img">
      <span class="title-span">车场饱和度</span>
    </div>
    <div class="imgBox d-flex ai-center jc-center">
      <dv-decoration-9 style="width: 150px; height: 150px"
        >{{ config.saturation }}%</dv-decoration-9
      >
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      url: "/park/base/datascreen/parkLotSaturation",
      parkLotId: "",
      config: {
        saturation: 0,
      },
      timer: null,
    };
  },
  mounted() {},
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    init(id) {
      this.parkLotId = id;
      this.getData();
    },
    getData() {
      this.getAction(this.url, { parkLotId: this.parkLotId }).then((res) => {
        // console.log("车场饱和度", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          this.config = res.result;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>

<style scoped lang="scss" class>
.title-img {
  height: 79px;
  background-image: url("../../assets/imgs/titleImg7.png");
  background-size: 204% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding: 0 18px 0 78px;
  margin-bottom: 6px;
  z-index: 1;
  line-height: 29px;
}
.title-span {
  margin-top: 6px;
}
.imgBox {
  box-sizing: border-box;
  width: 302px;
  height: 219px;
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  margin-top: -37px;
  background: rgba(25, 96, 195, 0.1);
  // background-image: url("../../assets/imgs/boxImg8.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  z-index: 2;
  padding: 0 10px;
}
</style>
