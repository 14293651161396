<template>
  <div>
    <div
      class="d-flex w-100 title-abs MaxTitle-img"
      style="justify-content: space-between"
    >
      <span class="title-span">车场信息</span>
    </div>
    <div class="d-flex MaxBox10-box">
      <a-carousel autoplay style="width: 470px; height: 330px; margin: 0">
        <div
          class="imgBox"
          v-for="(item, index) in form.urls"
          :key="'img' + index"
        >
          <img :src="item" alt="" />
        </div>
      </a-carousel>
      <div class="rightBox">
        <div class="parkLotName">{{ form.name }}车场介绍</div>
        <!-- <div class="division"></div> -->
        <dv-decoration-2
          style="width: 100%; height: 10px; margin-bottom: 15px"
          :dur="3"
        />
        <div class="contentBox">
          <div class="scrollBox" ref="scrollBox">
            <div v-for="item in form.parkInfoConnections" :key="item.id">
              <div class="introduceTitle">
                {{ item.title }}
              </div>
              <div class="introduceContent">
                {{ item.content }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      form: {},
      ParkLotList: [],
      parkLotId: "",
    };
  },
  mounted() {
    this.getParkLotList();
  },
  methods: {
    // 关闭地图是恢复有数据的车场介绍
    close() {
      console.log(12);
      this.parkLotIdGet(this.parkLotId);
    },
    // 车场列表
    getParkLotList() {
      this.getAction("/park/base/datascreen/getParkLotList").then((res) => {
        if (res.success) {
          console.log("车场列表", res);
          this.ParkLotList = res.result;
          let ParkLotList = JSON.parse(JSON.stringify(this.ParkLotList));
          for (let index = 0; index < ParkLotList.length; index++) {
            const element = ParkLotList[index];
            if (element.parkInfoConnections) {
              this.parkLotId = element.id;
              this.form = element;
              setTimeout(() => {
                this.startScrolling();
              }, 1000);
              return true;
            }
          }
          if (this.parkLotId) {
            this.parkLotId = ParkLotList[0].id;
            this.parkLotIdGet(this.parkLotId);
          }
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    startScrolling() {
      const scrollBox = this.$refs.scrollBox;
      const textHeight = scrollBox.scrollHeight;
      const duration = textHeight < 200 ? 0 : (textHeight / 20).toFixed(2); // 滚动速度为 20 像素/秒
      scrollBox.style.animationDuration = `${duration}s`;
      scrollBox.style.animationDelay = "3s";
    },
    parkLotIdGet(parkLotId) {
      console.log(parkLotId);
      let ParkLotList = JSON.parse(JSON.stringify(this.ParkLotList));
      let item = ParkLotList.find((res) => res.id == parkLotId);
      console.log("车场介绍", item);
      this.form = item;
      setTimeout(() => {
        this.startScrolling();
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/boxStyle.scss";
.imgBox {
  width: 530px;
  height: 298px;
  margin: 0;
}
.imgBox img {
  width: 100%;
  height: 298px;
  margin: 0;
}

::v-deep .ant-carousel .slick-dots {
  text-align: right;
  padding-right: 10px;
}

::v-deep .ant-carousel .slick-dots li {
  background-color: #999;
}

::v-deep .ant-carousel .slick-dots li.slick-active button {
  background-color: rgb(23, 234, 254);
}

.rightBox {
  height: 330px;
  width: 820px;
  margin-left: 20px;
  .parkLotName {
    font-size: 22px;
    font-weight: bold;
    color: #66ffff;
  }
}
.scrollBox {
  animation: scroll linear infinite;
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
.contentBox {
  height: 280px;
  overflow-y: auto;
}
.contentBox::-webkit-scrollbar {
  display: none;
}
.introduceTitle {
  font-size: 18px;
  font-weight: bold;
  color: #66ffff;
  display: flex;
  align-items: center;
}
.introduceTitle::before {
  content: "";
  display: inline-block;
  width: 7px;
  height: 18px;
  background: #66ffff;
  margin-right: 10px;
}
.introduceContent {
  font-size: 18px;
  font-weight: 400;
  color: #deeaff;
  line-height: 26px;
  white-space: pre-line;
}
</style>
