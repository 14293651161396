import { render, staticRenderFns } from "./sports.vue?vue&type=template&id=b289eba0&scoped=true&"
import script from "./sports.vue?vue&type=script&lang=js&"
export * from "./sports.vue?vue&type=script&lang=js&"
import style0 from "./sports.vue?vue&type=style&index=0&id=b289eba0&prod&scoped=true&lang=scss&class=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b289eba0",
  null
  
)

export default component.exports