<template>
  <div class="ParkLot">
    <div class="ParkLotbox">
      <span class="ParkLotboxtitle">机械车场链接：</span>
      <span class="ParkLotname" @click="goWeb">鑫创达停车云管理系统</span>
      <!-- <span v-for="item in ParkLotList" :key="item.id" class="ParkLotname">{{
        item.name
      }}</span> -->
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    ParkLotList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    goWeb() {
      window.open("https://xmxcd.yunzutai.com");
    },
  },
};
</script>
<style scoped lang="scss">
.ParkLot {
  box-sizing: border-box;
  width: 1306px;
  height: 71px;
  padding: 10px 56px 10px 23px;
  position: relative;
  z-index: 2;
  // background: rgba(25, 96, 195, 0.3);
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // background-image: url("../assets/imgs/boxImg7.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  .ParkLotbox {
    width: 100%;
    height: 100%;
    // background: red;
    overflow-x: auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .ParkLotboxtitle {
      font-size: 22px;
      font-weight: bold;
      color: #deeaff;
    }
    .ParkLotname {
      font-size: 22px;
      font-weight: 500;
      color: #66ffff;
      margin-right: 30px;
      line-height: 51px;
      // text-decoration: underline;
      text-decoration-line: underline;
      text-decoration-skip-ink: none;
      text-underline-offset: 7px; /* 调整距离的值，可以是像素、百分比或其他合适的单位 */
      cursor: pointer;
    }
  }
}

.ParkLotbox::-webkit-scrollbar {
  height: 5px; /* 设置滚动条高度 */
  width: 100%;
  cursor: pointer;
}
::-webkit-scrollbar-thumb {
  background-color: #888; /* 滚动条滑块颜色 */
  border-radius: 5px; /* 滑块边角弧度 */
}
</style>
