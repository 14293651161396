<template>
  <div>
    <div class="title-img">
      <span class="title-span"> {{ name }}车场详情</span>
    </div>
    <div class="fourBox d-flex jc-space">
      <topBox
        :title="'累计应收金额/元'"
        :total="form.totalAmount || 0"
        :unit="'元'"
        :labelOne="'今日营收额'"
        :labelTwo="'昨日营收额'"
        :dataOne="form.todayTotalAmount || 0"
        :dataTwo="form.yesterdayTotalAmount || 0"
      />
      <topBox
        :title="'累计成交订单/件'"
        :total="form.payTotal || 0"
        :unit="'件'"
        :labelOne="'今日成交数'"
        :labelTwo="'昨日成交数'"
        :dataOne="form.todayPayTotal || 0"
        :dataTwo="form.yesterdavPavTotal || 0"
      />
      <topBox
        :title="'总在场车辆/辆'"
        :total="form.floorVehicleTotal || 0"
        :unit="'辆'"
        :labelOne="'今日在场车辆'"
        :labelTwo="'昨日在场车辆'"
        :dataOne="form.todayFloorVehicle || 0"
        :dataTwo="form.yesterdayFloorVehicle || 0"
      />
      <topBox
        :title="'总进出场数'"
        :total="form.recordInTotal + '/' + form.recordOutTotal"
        :labelOne="'今日进出场数'"
        :labelTwo="'昨日进出场数'"
        :dataOne="form.todayRecordInTotal + '/' + form.todayRecordOutTotal"
        :dataTwo="
          form.yesterdayRecordInTotal + '/' + form.yesterdayRecordOutTotal
        "
      />
    </div>
  </div>
</template>
<script>
import topBox from "@/common/topBox";
export default {
  components: {
    topBox,
  },
  data() {
    return {
      form: {
        recordInTotal: 0,
        recordOutTotal: 0,
        todayRecordInTotal: 0,
        todayRecordOutTotal: 0,
        yesterdayRecordInTotal: 0,
        yesterdayRecordOutTotal: 0,
      },
      parkLotId: "",
      name: "",
      url: "/park/base/datascreen/homedata",
      timer: null,
    };
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    init(id, name) {
      this.parkLotId = id;
      this.name = name;
      this.getData();
    },
    getData() {
      this.getAction(this.url, { parkLotId: this.parkLotId }).then((res) => {
        // console.log("车场详情数据", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const result = Object.entries(res.result).reduce(
            (acc, [key, value]) => {
              acc[key] = value ? value : 0;
              return acc;
            },
            {}
          );
          this.form = result;
          // console.log(this.form);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>
<style lang="scss" scoped>
.title-img {
  width: 1266px;
  height: 79px;
  background-image: url("../../assets/imgs/titleImg6.png");
  background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding: 0 18px 0 78px;
  margin-bottom: 6px;
  z-index: 1;
}
.title-span {
  margin-top: 6px;
}
.fourBox {
  width: 1266px;
  height: 160px;
  margin-top: -37px;
}
</style>
