<template>
  <div class="centreBox">
    <div class="d-flex ai-center jc-center">
      <div class="topBox" v-if="dataShow">
        <div class="left d-flex ai-center">
          <span style="margin-right: 10px" class="topText">总营收额 </span>
          <!-- <div class="text">
            <dv-digital-flop
              :config="config"
              style="width: 350px; height: 60px"
            />
          </div> -->
          <span
            class="span"
            v-for="(item, index) in leftList"
            :key="'A' + index"
            >{{ item }}</span
          >
        </div>
        <div class="right d-flex ai-center">
          <span style="margin-right: 10px" class="topText">总订单量</span>
          <!-- <div class="text">
            <dv-digital-flop
              :config="configRight"
              style="width: 350px; height: 60px"
            />
          </div> -->
          <span
            class="span"
            v-for="(item, index) in rightList"
            :key="'B' + index"
            >{{ item }}</span
          >
        </div>
      </div>
    </div>
    <div class="mapsBoxTitxe">
      <div class="Titxe" @click="changebasemap" style="margin-bottom: 5px">
        切换地图
      </div>
      <div class="Titxe" @click="ondataShow">
        {{ dataShow ? "隐藏" : "显示" }}数据
      </div>
    </div>
    <div class="mapsBox">
      <div id="map-mark"></div>
    </div>
    <div class="bottom" v-if="dataShow">
      <bottomBox
        :title="'累计应收金额/元'"
        :total="form.totalAmount || 0"
        :unit="'元'"
        :labelOne="'今日营收额'"
        :labelTwo="'昨日营收额'"
        :dataOne="`¥${form.todayTotalAmount || 0}`"
        :dataTwo="`¥${form.yesterdayTotalAmount || 0}`"
      />
      <bottomBox
        :title="'累计成交订单/件'"
        :total="form.payTotal || 0"
        :unit="'件'"
        :labelOne="'今日成交数'"
        :labelTwo="'昨日成交数'"
        :dataOne="form.todayPayTotal"
        :dataTwo="form.yesterdavPavTotal"
      />
      <bottomBox
        :title="'总在场车辆'"
        :total="form.floorVehicleTotal || 0"
        :unit="'辆'"
        :labelOne="'今日在场车辆'"
        :labelTwo="'昨日在场车辆'"
        :dataOne="form.todayFloorVehicle"
        :dataTwo="form.yesterdayFloorVehicle"
      />
      <bottomBox
        :title="'车场总数/个'"
        :total="form.parkLotNum || 0"
        :unit="'个'"
        :labelOne="'车位总数'"
        :labelTwo="'闸道总数'"
        :dataOne="form.spacesTotal"
        :dataTwo="form.parkDriveway"
      />
      <bottomBox
        :title="'总进出场数'"
        :total="form.recordInTotal || 0 + '/' + form.recordOutTotal"
        :labelOne="'今日进出场数'"
        :labelTwo="'昨日进出场数'"
        :dataOne="form.todayRecordInTotal || 0 + '/' + form.todayRecordOutTotal"
        :dataTwo="
          form.yesterdayRecordInTotal || 0 + '/' + form.yesterdayRecordOutTotal
        "
      />
      <bottomBox
        :title="'其他商城数据'"
        :total="0.0"
        :unit="'元'"
        :labelOne="'今日'"
        :labelTwo="'昨日'"
        :dataOne="0"
        :dataTwo="0"
      />
      <bottomBox
        :title="'其他商城数据'"
        :total="0.0"
        :unit="'元'"
        :labelOne="'数据1'"
        :labelTwo="'数据2'"
        :dataOne="'0'"
        :dataTwo="'0'"
      />
    </div>
  </div>
</template>
<script>
import bottomBox from "@/common/bottomBox";
// import maps from "./maps";
function formatter(number) {
  const numbers = number.toString().split("").reverse();
  const segs = [];

  while (numbers.length) segs.push(numbers.splice(0, 3).join(""));

  return segs.join(",").split("").reverse().join("");
}
export default {
  components: {
    bottomBox,
  },
  props: {
    // ParkLotList: {
    //   type: Array,
    //   default: () => [],
    // },
  },
  data() {
    return {
      dataShow: false,
      ParkLotList: [],
      infoWindow: null,
      configRight: {
        number: [0],
        formatter,
        textAlign: "left",
        style: {
          fontSize: 50,
          fill: "#00FFFF",
          lineHeight: "60px",
          textShadow:
            "1px 1px #00FFFF, 2px 2px #00FFFF, 3px 3px #00FFFF, 4px 4px #00FFFF, 5px 5px #00FFFF, 6px 6px #00FFFF",
        },
      },
      config: {
        number: [0],
        formatter,
        textAlign: "left",
        style: {
          fontSize: 50,
          fill: "#00FFFF",
          lineHeight: "60px",
          textShadow:
            "1px 1px #00FFFF, 2px 2px #00FFFF, 3px 3px #00FFFF, 4px 4px #00FFFF, 5px 5px #00FFFF, 6px 6px #00FFFF",
        },
      },
      form: {
        recordOutTotal: 0,
        todayRecordOutTotal: 0,
        yesterdayRecordOutTotal: 0,
      },
      num: 12,
      leftList: [0],
      rightList: [0],
      url: "/park/base/datascreen/homedata",
      topurl: "/park/base/datascreen/homedata",
      map: null, // 地图对象
      marker: null,
      timer: null,
      mapType: "地图",
      showId: "", //记录鼠标移入的信息窗口id用于再次移入关闭
    };
  },
  mounted() {
    this.getForm();
    this.getTop();
    this.getParkLotList();
    this.startTimer();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 数据显示隐藏
    ondataShow() {
      this.dataShow = !this.dataShow;
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getForm();
        this.getTop();
        // this.config.number[0] = this.config.number[0] + 123;
        // let num = this.config.number[0] + 123;
        // this.$set(this.config, "number", [num]);
        // console.log("num", num);
      }, 60 * 1000);
    },
    getForm() {
      this.getAction(this.url).then((res) => {
        // console.log("底部首页数据", res);
        if (res.success) {
          Object.keys(res.result).forEach((key) => {
            res.result[key] = res.result[key] || 0;
          });
          this.form = res.result;
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    getTop() {
      this.getAction(this.topurl).then((res) => {
        // console.log("头部首页数据", res);
        if (res.success) {
          // this.config.number[0] = Math.round(res.result.totalAmount) || 0; //营业额
          // this.config = {
          //   ...this.config,
          //   number: [this.config.number[0]],
          // };
          // this.configRight.number[0] = Math.round(res.result.payTotal) || 0; //订单量
          // this.configRight = {
          //   ...this.configRight,
          //   number: [this.configRight.number[0]],
          // };
          // console.log(this.config.number[0]);
          let leftList = Math.round(res.result.totalAmount) || 0; //营业额
          let rightList = Math.round(res.result.payTotal) || 0; //订单量
          this.leftList = Array.from(String(leftList), Number);
          this.rightList = Array.from(String(rightList), Number);
          // console.log(this.leftList, rightList);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 切换地图
    changebasemap() {
      this.mapType = this.mapType == "地图" ? "卫星" : "地图";
      if (this.mapType == "地图") {
        this.map.setBaseMap({ type: "vector" });
      } else if (this.mapType == "卫星") {
        this.map.setBaseMap({ type: "satellite" });
      }
    },
    // 车场列表
    getParkLotList() {
      this.getAction("/park/base/datascreen/getParkLotList").then((res) => {
        if (res.success) {
          // console.log("车场列表", res);
          this.ParkLotList = res.result;
        } else {
          this.$message.warning(res.message);
        }
        this.$nextTick(() => {
          this.initMap(); //初始化地图
        });
      });
    },
    initMap() {
      // console.log("ParkLotList", this.ParkLotList);
      let latitude = this.ParkLotList[0]
        ? this.ParkLotList[0].latitude
        : 39.9087;
      let longitude = this.ParkLotList[0]
        ? this.ParkLotList[0].longitude
        : 116.3975;

      var center = new window.TMap.LatLng(latitude, longitude); //设置中心点坐标
      this.map = new window.TMap.Map(document.getElementById("map-mark"), {
        center: center,
        zoom: 14,
        mapStyleId: "style1",
        // pitch: 43.5,
        // rotation: 45,
      });
      this.map.removeControl(window.TMap.constants.DEFAULT_CONTROL_ID.ZOOM);
      let markList = [];
      for (let i = 0; i < this.ParkLotList.length; i++) {
        let item = {
          id: this.ParkLotList[i].id,
          styleId: "marker",
          name: this.ParkLotList[i].name,
          totalSpaces: this.ParkLotList[i].totalSpaces || 0,
          position: new window.TMap.LatLng(
            this.ParkLotList[i].latitude,
            this.ParkLotList[i].longitude
          ), //标记位置(纬度，经度，高度)
        };
        markList.push(item);
      }
      // 创建MultiMarker对象
      var marker = new window.TMap.MultiMarker({
        map: this.map,
        styles: {
          marker: new window.TMap.MarkerStyle({
            width: 45, // 样式宽
            height: 47, // 样式高
            src: "custom-icon.png",
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        geometries: markList,
      });
      //初始化infoWindow
      this.infoWindow = new window.TMap.InfoWindow({
        map: this.map,
        position: new window.TMap.LatLng(39.984104, 116.307503),
        offset: { x: 0, y: -32 }, //设置信息窗相对position偏移像素
      });
      this.infoWindow.close(); //初始关闭信息窗关闭
      marker.on("click", this.eventClick);
      marker.on("mouseover", this.eventMouseover);
      marker.on("mouseout", this.eventMouseout);
    },

    eventMouseover(evt) {
      //监听标注移入事件
      //设置infoWindow
      // console.log(evt);
      if (this.showId == evt.geometry.id) {
        this.infoWindow.close(); //再次移入关闭关闭信息窗关闭
        this.showId = "";
      } else {
        this.infoWindow.open(); //打开信息窗
        this.showId = evt.geometry.id;
        this.infoWindow.setPosition(evt.geometry.position); //设置信息窗位置
        this.infoWindow
          .setContent(`<div  id="infoBox" style="padding: 16px 20px 12px !important;">
        <div style=" margin-bottom: 8px;">${evt.geometry.name}</div>
        <div>车位数：${evt.geometry.totalSpaces}</div>
        </div>`); //设置信息窗内容
      }
    },
    eventClick(evt) {
      // console.log("Marker ID:", markerId);
      this.$emit("onMap", evt.geometry.id);
      // 在这里执行处理标记点点击事件的逻辑，可以根据 markerId 进行相应的操作
    },
    eventMouseout(){
      this.infoWindow.close();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .text canvas {
  text-shadow: 1px 1px #00ffff, 2px 2px #00ffff, 3px 3px #00ffff,
    4px 4px #00ffff, 5px 5px #00ffff, 6px 6px #00ffff !important;
}
.centreBox {
  width: 1750px;
  height: 900px;
  // box-sizing: border-box;
  // background: rgba(25, 96, 195, 0.3);
  // background-image: url("../assets/imgs/boxImg4.png");
  background-size: 100% 100%;
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  .topBox {
    width: 1603px;
    font-size: 31px;
    // font-weight: bold;
    color: #66ffff;
    display: flex;
    justify-content: space-around;
    // background-color: #66ffff;
    align-items: center;
    padding: 12px;
    position: absolute;
    top: 15px;
    z-index: 5;
    .topText {
      letter-spacing: 2px;
      text-shadow: 0px 3px 6px #000000;
    }
    .text {
      padding-top: 9px;
    }
    .span {
      width: 75px;
      height: 85px;
      margin-right: 8px;
      display: inline-block;
      text-align: center;
      line-height: 85px;
      font-size: 53px;
      font-weight: 600;
      // color: #ffd700;
      color: #fff;
      background-image: url("../assets/imgs/scoreboard2.png");
      background-color: rgba(13, 51, 116, 0.9);
      background-position: center;
      background-size: 100% 100%;
      background-repeat: no-repeat; /* 不重复平铺背景图片 */
      // box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.25);
    }
  }
  .mapsBoxTitxe {
    position: absolute;
    top: 24px;
    right: -20px;
    font-size: 22px;
    z-index: 9;
    .Titxe {
      border-radius: 10px;
      padding: 3px 15px;
      border: 1px solid rgb(30, 144, 255);
      background: rgba(13, 51, 116, 0.3);
      color: #fff;
      cursor: pointer;
    }
  }
  ::v-deep #map-mark div:first-child div:first-child div:first-child {
    background: none !important;
    padding: 0 !important;
  }
  ::v-deep #map-mark div:first-child div:first-child div:nth-child(2) {
    background: none !important;
  }
  ::v-deep #map-mark #infoBox {
    text-align: left;
    font-size: 22px;
    font-weight: 800;
    color: #fff;
    // padding: 25px 10px 12px;
    // background: #013183;
    background: rgb(1, 49, 131) !important;
    background: radial-gradient(
      circle,
      rgba(1, 49, 131, 0.1) 0%,
      rgba(17, 120, 241, 1) 100%
    ) !important;
    border-radius: 10px;
  }
  .mapsBox {
    width: 1740px;
    height: 850px;
    border-radius: 10px;
    position: absolute;
    z-index: 4;
    top: 25px;
    left: 30px;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    #map-mark {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      // pointer-events: none;
      cursor: pointer;
      ::v-deep canvas {
        border-radius: 40px 10px 40px 40px;
      }
    }
  }
  ::v-deep .rotate-circle {
    margin-top: 80px;
    display: none;
  }
  ::v-deep .tmap-zoom-control {
    margin-top: 90px;
  }
  ::v-deep #map-mark .tmap-zoom-in {
    background: #fff !important;
  }
}
.bottom {
  position: absolute;
  // width: 1750px;
  width: 100%;
  height: 241px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.7) 100%
  );
  // margin-bottom: 20px;
  left: 20px;
  bottom: 25px;
  display: flex;
  border-radius: 20px;
  justify-content: space-evenly;
  align-items: flex-end;
  z-index: 5;
}
.info_card {
  text-align: left;
}
</style>
