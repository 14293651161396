<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">车场总收入曲线统计</span>
      <div>
        <a-select
          :value="parkLotId"
          style="width: 120px"
          @change="handleChange"
        >
          <a-select-option value=""> 全部车场 </a-select-option>
          <a-select-option
            v-for="item in ParkLotList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-date-picker
          @change="onChange"
          :value="dateYear"
          :allowClear="false"
          style="width: 120px; margin-left: 10px"
          class="custom"
        >
          <img
            src="@/assets/imgs/time.png"
            style="width: 20px; height: 20px"
            slot="suffixIcon"
            alt=""
        /></a-date-picker>
      </div>
    </div>
    <div class="imgbox">
      <Echart
        ref="chart"
        :options="options"
        :class="'echarts-chart'"
        id="incomeChart"
        height="255px"
        width="1930px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { formatTime } from "../utils/index.js";
export default {
  components: {
    Echart,
  },
  props: {
    ParkLotList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        title: {
          show: false,
        },
        // 配置提示框
        tooltip: {
          trigger: "axis",
          formatter: function (params) {
            let tooltip = params[0].name + "<br/>";
            tooltip += "营收(元): " + params[0].value + "<br/>";
            tooltip += "订单量: " + params[1].value;
            return tooltip;
          },
        },
        legend: {
          data: ["营收(元)", "订单量"],
          top: 5,
          textStyle: {
            // 设置 Legend 文字样式
            color: "#fff", // 修改折线图例颜色
          },
        },
        xAxis: [
          {
            type: "category",
            data: [],
            axisPointer: {
              type: "shadow",
            },
            axisLabel: {
              color: "rgb(255,255,255)", // 修改 x 轴字体颜色
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            name: "营收(元)",
            axisLabel: {
              color: "rgb(255,255,255)", // 修改 y 轴字体颜色
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 将横向参考线修改为虚线
                color: "#fff", // 设置横向参考线的颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#fff", // 设置 Y 轴刻度线的颜色
              },
            },
          },
          {
            type: "value",
            name: "订单量",
            axisLabel: {
              color: "rgb(255,255,255)", // 修改 y 轴字体颜色
            },
            splitLine: {
              lineStyle: {
                type: "dashed", // 将横向参考线修改为虚线
                color: "#fff", // 设置横向参考线的颜色
              },
            },
            axisLine: {
              lineStyle: {
                color: "#fff", // 设置 Y 轴刻度线的颜色为红色
              },
            },
          },
        ],
        grid: {
          height: "70%",
          top: 35,
          left: 50,
          // right: 5,
        },
        series: [
          {
            name: "营收(元)",
            type: "bar",
            data: [],
            itemStyle: {
              // 设置柱状图样式
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "rgba(135,206,250,0.8)" }, // 渐变起始颜色
                { offset: 1, color: "rgba(30,144,255,1)" }, // 渐变结束颜色
              ]),
            },
          },
          {
            name: "订单量",
            type: "line",
            yAxisIndex: 1,
            data: [],
            label: {
              // 设置标签显示
              show: true, // 显示标签
              position: "top", // 标签显示在数据点的上方
              color: "#fff", // 设置数据点标签的字体颜色
            },
            lineStyle: {
              // 设置折线样式
              color: "rgb(28,252,123)", // 修改折线颜色
              type: "dashed", // 设置折线为虚线
            },
            itemStyle: {
              color: "rgb(28,252,123)", // 设置数据点的颜色为
              borderColor: "#fff", // 设置数据点边框的颜色
              borderWidth: 2, // 设置数据点边框的宽度为2
            },
            // smooth: true,
          },
        ],
      },
      url: "/park/base/datascreen/totalAmountCurveStatistics",
      dateYear: null,
      parkLotId: "",
      timer: null,
    };
  },
  mounted() {
    this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url, {
        date: this.dateYear,
        parkLotId: this.parkLotId,
      }).then((res) => {
        // console.log("车场总收入曲线统计", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const xAxis = res.result.map((car) => car.date + ":00");
          const totalAmount = res.result.map((car) => car.totalAmount || 0);
          const orderQuantity = res.result.map((car) => car.orderQuantity || 0);
          this.options.series[0].data = totalAmount; //营收(元)
          this.options.series[1].data = orderQuantity; //订单
          this.options.xAxis[0].data = xAxis;
          // console.log(xAxis);
          // console.log(totalAmount);
          // console.log(orderQuantity);
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
    // 时间选择
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.dateYear = dateString
        ? dateString
        : formatTime(new Date(), "yyyy-MM-dd");
      this.getData();
    },
    // 车场选择
    handleChange(value) {
      console.log(`selected ${value}`);
      this.parkLotId = value;
      this.getData();
    },
  },
};
</script>
<style scoped lang="scss" class>
::v-deep .ant-calendar-picker-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
  cursor: pointer;
}
::v-deep .ant-calendar-picker-icon {
  cursor: pointer;
}
::v-deep .ant-select-arrow {
  color: #fff;
}
::v-deep .ant-select-selection {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
}
.imgbox {
  width: 1796px;
  height: 245px;
  margin-top: -36px;
  position: relative;
  z-index: 2;
  // padding-top: 20px;
  // background: rgba(25, 96, 195, 0.3);
  // background-image: url("../assets/imgs/boxImg5.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
}
.title-img {
  width: 1795px;
  height: 79px;
  background-image: url("../assets/imgs/titleImg4.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding: 0 18px 0 78px;
  margin-bottom: 6px;
  line-height: 29px;
}
.title-span {
  margin-top: 6px;
}
</style>
