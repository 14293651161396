<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">各车场饱和度情况</span>
    </div>

    <div class="box10-box" style="padding: 0">
      <Echart
        ref="chart"
        :options="options"
        id="saturationChart"
        height="290px"
        width="740px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { formatTime } from "../utils/index.js";

export default {
  components: {
    Echart,
  },
  data() {
    return {
      options: {
        title: {
          show: false,
        },
        yAxis: {
          type: "category",
          data: [],
          axisLabel: {
            color: "rgb(255,255,255)",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "value",
          min: 0,
          max: 100,
          axisLabel: {
            formatter: "{value} %",
            color: "rgb(255,255,255)",
            fontSize: 12,
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#133d83",
            },
          },
        },
        grid: {
          width: "70%",
          left: 100,
          top: 1,
        },
        series: [
          {
            type: "bar",
            data: [],
            itemStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 1, 0, [
                {
                  offset: 0,
                  color: "#2DDB7F",
                  // color: "#17eafe",
                }, //柱图渐变色
                {
                  offset: 1,
                  color: "#17eafe",
                  // color: "#2DDB7F",
                },
              ]),
              barBorderRadius: [0, 10, 10, 0],
            },
            label: {
              show: true,
              position: "right",
              formatter: "{c} %",
              color: "#fff",
              fontSize: 12,
            },
            barCategoryGap: "50%",
            animationDelay: function (idx) {
              return idx * 10;
            },
          },
        ],
        tooltip: {
          trigger: "axis",
          formatter: "{b} : {c}%",
          axisPointer: {
            type: "shadow",
          },
        },
      },
      url: "/park/base/datascreen/parkLotsSaturation",
      dateYear: null,
      timer: null,
    };
  },
  mounted() {
    this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url, { date: this.dateYear }).then((res) => {
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const data = res.result.map((car) => ({
            // name: car.parkLotName,
            name: car.parkLotName
              ? car.parkLotName.length > 6
                ? car.parkLotName.slice(0, 6) + "..."
                : car.parkLotName
              : "",
            value: car.saturation ? Math.round(car.saturation * 100) : 0,
          }));
          data.sort((a, b) => a.value - b.value);
          const yAxis = data.map((item) => item.name);
          this.options.series[0].data = data;
          this.options.yAxis.data = yAxis;
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    startTimer() {
      this.timer = setInterval(() => {
        this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        this.getData();
      }, 60 * 1000);
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/boxStyle.scss";
::v-deep .ant-calendar-picker-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
  cursor: pointer;
}
::v-deep .ant-calendar-picker-icon {
  cursor: pointer;
}
</style>
