<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">实时进出场车辆情况</span>
      <!-- <a-button @click="go()">点击</a-button> -->
    </div>
    <div class="box10-box" style="padding: 0 24px">
      <dv-scroll-board
        style="width: 100%; height: 100%"
        :config="config"
        ref="scrollBoard"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      config: {
        header: ["车场名称", "车牌号", "时间", "类型", "停车时长", "收入(元)"],
        data: [],
        index: false,
        // columnWidth: [50],
        align: ["left", "center", "center", "center", "center", "center"],
        headerBGC: "#274B9D",
      },
      url: "/park/base/datascreen/recordInAndOut",
      timer: null,
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
    getData() {
      this.getAction(this.url).then((res) => {
        // console.log("实时进出场车辆情况", res);
        if (!this.timer) {
          this.startTimer();
        }
        if (res.success) {
          this.config.data = [];
          for (let index = 0; index < res.result.length; index++) {
            const element = res.result[index];
            let arr = [];
            arr.push(element.parkLotName); //车场名称
            arr.push(element.carnum || "0"); //车牌号
            arr.push(element.time || "00:00:00"); //时间
            element.inoutType = element.inoutType == "0" ? "进场" : "出场"; //类型: 0.进场 1.出场
            arr.push(element.inoutType); //类型
            // element.elapsedTimeStr = element.elapsedTimeStr
            //   ? element.elapsedTimeStr + "h"
            //   : "0h";
            arr.push(element.elapsedTimeStr || "0h"); //停车时长
            arr.push(element.payAmount || "0"); //收入(元)
            const stringArray = arr.map((value) => String(value));
            this.config.data.push(stringArray);
          }
          // console.log(this.config.data);
          this.$refs["scrollBoard"].updateRows(this.config.data);
        } else {
          if (res.message == "请重新登录!") {
            this.$message.warning(res.message);
            setTimeout(() => {
              let URL = process.env.VUE_APP_CREATE_ACTIVITY_PCURL;
              console.log(URL);
              window.location.replace(URL);
            }, 1000);
          }
          this.$message.warning(res.message);
        }
      });
    },
    // go() {
    //   let URL = process.env.VUE_APP_CREATE_ACTIVITY_PCURL;
    //   console.log(URL);
    //   // location.href = URL;
    //   window.location.replace(URL);
    // },
  },
};
</script>

<style scoped lang="scss" class>
@import "../assets/scss/boxStyle.scss";
::v-deep .header {
  background-image: linear-gradient(
    to bottom,
    rgb(26, 67, 145),
    rgb(70, 113, 203)
  );
  margin-bottom: 6px;
}
::v-deep .row-item {
  background: none !important;
}
</style>
