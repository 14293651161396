<template>
  <div class="bottomBox">
    <div class="titleBox">
      <img
        src="../assets/imgs/data.png"
        style="width: 21px; height: 20px"
      /><span class="titleBox-title">{{ title }}</span>
    </div>
    <div class="title-button-color"></div>
    <div class="bottom">
      <div class="total">
        {{ total || 0
        }}<span
          style="
            font-size: 16px;
            margin-left: 8px;
            font-weight: 400;
            color: #26b0ff;
          "
          >{{ unit }}</span
        >
      </div>
      <div class="amount" style="margin-bottom: 4px">
        {{ labelOne }}：<span>{{ dataOne || 0 }}</span>
      </div>
      <div class="amount">
        {{ labelTwo }}：<span>{{ dataTwo || 0 }}</span>
      </div>
    </div>
    <div class="title-button-color"></div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: "",
    },
    total: {
      type: [String, Number],
      required: true,
    },
    labelOne: {
      type: String,
      required: false,
      default: "",
    },
    dataOne: {
      type: [String, Number],
      required: false,
      default: "",
    },
    labelTwo: {
      type: String,
      required: false,
      default: "",
    },
    dataTwo: {
      type: [String, Number],
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.bottomBox-color {
  color: rgba(255, 255, 255, 1);
}
.bottomBox {
  margin-bottom: 20px;
  .titleBox {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .titleBox-title {
      font-size: 20px;
      font-weight: 400;
      margin-left: 7px;
      color: #26b0ff;
    }
  }

  .bottom {
    width: 210px;
    height: 96px;
    background-image: url("../assets/imgs/mapImg.png");
    background-size: 100% 100%; /* 调整图片尺寸以填满元素 */
    background-repeat: no-repeat; /* 不重复平铺背景图片 */

    .total {
      font-size: 28px;
      font-weight: bold;
      color: #66ffff;
      margin-bottom: 0px;
    }
    .amount {
      font-size: 16px;
      font-weight: 400;
      color: #26b0ff;
    }
  }
}
</style>
