<template>
  <div>
    <!-- <dv-border-box-10> -->
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">各车场收入排行</span>
      <div class="d-flex ai-center" style="height: 30px">
        <a-date-picker
          style="width: 120px"
          @change="onChange"
          :value="dateYear"
          :allowClear="false"
        >
          <img
            src="@/assets/imgs/time.png"
            style="width: 20px; height: 20px"
            slot="suffixIcon"
            alt=""
          />
        </a-date-picker>
      </div>
    </div>
    <!-- <dv-border-box-10 style="height: 80%"> -->
    <div class="box10-box" style="padding: 0 24px">
      <dv-scroll-board
        style="width: 100%; height: 100%"
        :config="config"
        ref="scrollBoard"
      />
    </div>
    <!-- </dv-border-box-10> -->
  </div>
</template>

<script>
import { formatTime } from "../utils/index.js";
export default {
  components: {},
  data() {
    return {
      config: {
        header: [
          "排名",
          "车场名称",
          "累计进场",
          "累计出场",
          "闸道数量",
          "车位总数",
          "车位余量",
          "收入(元)",
        ],
        data: [],
        index: false,
        // columnWidth: [50],
        align: [
          "center",
          "left",
          "center",
          "center",
          "center",
          "center",
          "center",
        ],
        // headerBGC: "#274B9D",
      },
      url: "/park/base/datascreen/incomecharts",
      dateYear: null,
      timer: null,
    };
  },
  mounted() {
    this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    // console.log(this.dateYear);
    this.getData(this.dateYear);
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url, { date: this.dateYear }).then((res) => {
        // console.log("各车场收入排行", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          this.config.data = [];
          for (let index = 0; index < res.result.length; index++) {
            const element = res.result[index];
            let arr = [];
            if (index == 0) {
              arr.push('<span class="one spanBI"></span>');
            } else if (index == 1) {
              arr.push('<span class="two spanBI"></span>');
            } else if (index == 2) {
              arr.push('<span class="three spanBI"></span>');
            } else {
              arr.push(index + 1); //排名
            }
            arr.push(element.parkLotName); //车场名称
            arr.push(element.recordInNum || "0"); //累计进场
            arr.push(element.recordOutNum || "0"); //累计出场
            arr.push(element.drivewayNum || "0"); //闸道数量
            arr.push(element.totalSpaces || "0"); //车位总数
            arr.push(element.remainSpaces || "0"); //车位余量
            arr.push(element.totalAmount || "0"); //收入(元)
            const stringArray = arr.map((value) => String(value));
            this.config.data.push(stringArray);
          }
          // console.log(this.config.data);
          this.$refs["scrollBoard"].updateRows(this.config.data);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.dateYear = dateString
        ? dateString
        : formatTime(new Date(), "yyyy-MM-dd");
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss">
@import "../assets/scss/boxStyle.scss";
// 修改 日期选择框的属性
::v-deep .ant-calendar-picker-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
  cursor: pointer;
}
::v-deep .ant-calendar-picker-icon {
  cursor: pointer;
}
::v-deep .header {
  background-image: linear-gradient(
    to bottom,
    rgb(26, 67, 145),
    rgb(70, 113, 203)
  );
  margin-bottom: 6px;
  font-size: 14px;
}
::v-deep .row-item {
  background: none !important;
}
::v-deep .one {
  background-image: url("../assets/imgs/one.png");
}
::v-deep .two {
  background-image: url("../assets/imgs/two.png");
}
::v-deep .three {
  background-image: url("../assets/imgs/three.png");
}
::v-deep .spanBI {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-position: center; /* 图片在元素中的位置 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
}
</style>
