<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">车位变化趋势统计图</span>
      <div class="d-flex ai-center" style="height: 30px">
        <a-date-picker
          style="width: 120px"
          @change="onChange"
          :value="dateYear"
          :allowClear="false"
        >
          <img
            src="@/assets/imgs/time.png"
            style="width: 20px; height: 20px"
            slot="suffixIcon"
            alt=""
          />
        </a-date-picker>
      </div>
    </div>

    <div class="box10-box" style="padding: 10px 0 0 10px">
      <Echart
        ref="chart"
        :options="options"
        id="parkingLotstallChart"
        height="220px"
        width="600px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { formatTime } from "@/utils/index.js";
export default {
  components: {
    Echart,
  },
  props: {
    ParkLotList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 x 轴字体颜色
          },
        },
        yAxis: {
          type: "value",
          name: "车位总数/个",
          nameGap: 25, // 调整name文字和轴线的间距
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 y 轴字体颜色
          },
        },
        grid: {
          height: "65%",
          top: 40,
          left: 40,
          right: 15,
        },
        series: [
          {
            type: "line",
            smooth: true,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(135,206,250,0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(30,144,255,1)",
                },
              ]),
            },
            label: {
              // 设置标签显示
              show: true, // 显示标签
              position: "top", // 标签显示在数据点的上方
              color: "#fff", // 设置数据点标签的字体颜色
            },
            lineStyle: {
              color: "rgba(30,144,255,1)", // 设置折线颜色
            },
            data: [],
          },
        ],
        // 配置提示框
        tooltip: {
          trigger: "axis", // 设置触发类型为坐标轴轴线上
          formatter: "{b} : {c}", // 显示类别名称和对应数值
          axisPointer: {
            type: "line", // 将悬浮指示器类型设为直线
            lineStyle: {
              // 设置直线样式
              color: "rgb(255,255,255)", // 指示器线条颜色
              width: 1, // 指示器线条宽度
            },
          },
        },
      },
      url: "/park/base/datascreen/spaceChangeStatistics",
      dateYear: null,
      parkLotId: "",
      timer: null,
    };
  },
  mounted() {
    // this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    // this.init();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    init(id) {
      this.parkLotId = id;
      this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
      this.getData();
    },
    getData() {
      this.getAction(this.url, {
        date: this.dateYear,
        parkLotId: this.parkLotId,
      }).then((res) => {
        // console.log("车位变化趋势统计图", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const xAxis = res.result.map((car) => car.date + ":00");
          const yAxis = res.result.map((car) => car.totalSpaces || 0);
          this.options.series[0].data = yAxis;
          this.options.xAxis.data = xAxis;
          // console.log(xAxis);
          // console.log(yAxis);
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },

    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
    // 时间选择
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.dateYear = dateString
        ? dateString
        : formatTime(new Date(), "yyyy-MM-dd");
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss" class>
$box-height: 270px;
$box-width: 100%;

// 修改 日期选择框的属性
::v-deep .ant-calendar-picker-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
  cursor: pointer;
}
::v-deep .ant-calendar-picker-icon {
  cursor: pointer;
}
::v-deep .ant-select-arrow {
  color: #fff;
}
::v-deep .ant-select-selection {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
}
.title-img {
  height: 79px;
  background-image: url("../../assets/imgs/titleImg8.png");
  background-size: cover; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  color: #deeaff;
  font-size: 22px;
  padding-left: 78px;
  padding-right: 18px;
  margin-bottom: 6px;
  line-height: 29px;
  z-index: 1;
}
.title-span {
  margin-top: 6px;
}
.box10-box {
  box-sizing: border-box;
  width: 618px;
  height: 218px;
  background: rgba(25, 96, 195, 0.1);
  // box-shadow: inset 0px 0px 40px 1px #2952af;
  // border: 2px solid #2952af;
  margin-top: -37px;
  // background-image: url("../../assets/imgs/boxImg9.png");
  background-size: 100%; /* 调整图片尺寸以填满元素 */
  background-repeat: no-repeat; /* 不重复平铺背景图片 */
  position: relative;
  z-index: 2;
}
</style>
