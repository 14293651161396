<template>
  <div>
    <div
      class="d-flex w-100 title-abs title-img"
      style="justify-content: space-between"
    >
      <span class="title-span">车位变化趋势统计图</span>
      <div>
        <a-select
          :value="parkLotId"
          style="width: 120px"
          @change="handleChange"
        >
          <a-select-option value=""> 全部车场 </a-select-option>
          <a-select-option
            v-for="item in ParkLotList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-date-picker
          @change="onChange"
          :value="dateYear"
          :allowClear="false"
          style="width: 120px; margin-left: 10px"
          class="custom"
        >
          <img
            src="@/assets/imgs/time.png"
            style="width: 20px; height: 20px"
            slot="suffixIcon"
            alt=""
        /></a-date-picker>
      </div>
    </div>
    <div class="box10-box" style="padding: 0">
      <Echart
        ref="chart"
        :options="options"
        id="stallChart"
        height="280px"
        width="640px"
      ></Echart>
    </div>
  </div>
</template>

<script>
import Echart from "@/common/echart";
import { formatTime } from "../utils/index.js";
export default {
  components: {
    Echart,
  },
  props: {
    ParkLotList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      options: {
        xAxis: {
          type: "category",
          data: [],
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 x 轴字体颜色
          },
        },
        yAxis: {
          type: "value",
          name: "车位总数/个",
          axisLabel: {
            color: "rgb(255,255,255)", // 修改 y 轴字体颜色
          },
        },
        grid: {
          height: "70%",
          top: 30,
          left: 40,
          right: 15,
        },
        series: [
          {
            type: "line",
            smooth: true,
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(135,206,250,0.8)",
                },
                {
                  offset: 1,
                  color: "rgba(30,144,255,1)",
                },
              ]),
            },
            label: {
              // 设置标签显示
              show: true, // 显示标签
              position: "top", // 标签显示在数据点的上方
              color: "#fff", // 设置数据点标签的字体颜色
            },
            lineStyle: {
              color: "rgba(30,144,255,1)", // 设置折线颜色
            },
            data: [],
          },
        ],
        // 配置提示框
        tooltip: {
          trigger: "axis", // 设置触发类型为坐标轴轴线上
          formatter: "{b} : {c}", // 显示类别名称和对应数值
          axisPointer: {
            type: "line", // 将悬浮指示器类型设为直线
            lineStyle: {
              // 设置直线样式
              color: "rgb(255,255,255)", // 指示器线条颜色
              width: 1, // 指示器线条宽度
            },
          },
        },
      },
      url: "/park/base/datascreen/spaceChangeStatistics",
      dateYear: null,
      parkLotId: "",
      timer: null,
    };
  },
  mounted() {
    this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
    this.getData();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    getData() {
      this.getAction(this.url, {
        date: this.dateYear,
        parkLotId: this.parkLotId,
      }).then((res) => {
        // console.log("车位变化趋势统计图", res);
        if (res.success) {
          if (!this.timer) {
            this.startTimer();
          }
          const xAxis = res.result.map((car) => car.date + ":00");
          const yAxis = res.result.map((car) => car.totalSpaces || 0);
          this.options.series[0].data = yAxis;
          this.options.xAxis.data = xAxis;
          // console.log(xAxis);
          // console.log(yAxis);
          this.$refs.chart.refresh(this.options);
        } else {
          this.$message.warning(res.message);
        }
      });
    },
    // 定时器
    startTimer() {
      this.timer = setInterval(() => {
        // this.dateYear = formatTime(new Date(), "yyyy-MM-dd");
        // 定时器逻辑
        this.getData();
      }, 60 * 1000);
    },
    // 时间选择
    onChange(date, dateString) {
      // console.log(date, dateString);
      this.dateYear = dateString
        ? dateString
        : formatTime(new Date(), "yyyy-MM-dd");
      this.getData();
    },
    // 车场选择
    handleChange(value) {
      console.log(`selected ${value}`);
      this.parkLotId = value;
      this.getData();
    },
  },
};
</script>

<style scoped lang="scss" class>
@import "../assets/scss/boxStyle.scss";
// 修改 日期选择框的属性
::v-deep .ant-calendar-picker-input {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
  cursor: pointer;
}
::v-deep .ant-calendar-picker-icon {
  cursor: pointer;
}
::v-deep .ant-select-arrow {
  color: #fff;
}
::v-deep .ant-select-selection {
  background-color: rgba(0, 0, 0, 0.1);
  border-color: rgba(30, 144, 255, 1);
  color: #fff;
}
</style>
